var goog = require('exports-loader?goog,soy,soydata,soyshim!/home/roma/www/biz-anatomy.ru/html/node_modules/closure-templates/soyutils.js').goog;
var soy = require('exports-loader?goog,soy,soydata,soyshim!/home/roma/www/biz-anatomy.ru/html/node_modules/closure-templates/soyutils.js').soy;
var soydata = require('exports-loader?goog,soy,soydata,soyshim!/home/roma/www/biz-anatomy.ru/html/node_modules/closure-templates/soyutils.js').soydata;
var soyshim = require('exports-loader?goog,soy,soydata,soyshim!/home/roma/www/biz-anatomy.ru/html/node_modules/closure-templates/soyutils.js').soyshim;
var loadingTemplate;
// This file was automatically generated from source.soy.
// Please don't edit this file by hand.

/**
 * @fileoverview Templates in namespace loadingTemplate.
 */

if (typeof loadingTemplate == 'undefined') { var loadingTemplate = {}; }


/**
 * @param {Object.<string, *>=} opt_data
 * @param {(null|undefined)=} opt_ignored
 * @param {Object.<string, *>=} opt_ijData
 * @return {!soydata.SanitizedHtml}
 * @suppress {checkTypes}
 */
loadingTemplate.loading = function(opt_data, opt_ignored, opt_ijData) {
  return soydata.VERY_UNSAFE.ordainSanitizedHtml('<div><div class="loading_bg loading js-loading-bg"></div></div>');
};
if (goog.DEBUG) {
  loadingTemplate.loading.soyTemplateName = 'loadingTemplate.loading';
}

module.exports = loadingTemplate;