import '../styles/index.less';
import './standard';
import 'hamburgers/dist/hamburgers.min.css';
import Slideout from 'slideout/index';
import 'slideout/index.css';
import adaptiveSwapper from 'adaptive-swapper';



$(() => {

  const $body = $('body');


  const isMobile = () => {
    return window.innerWidth < 960;
  };

  // Основное меню
  $('#js-main-nav').each(function () {
    const $menu = $(this);

    // Выпадающее меню
    $menu.find('li').has('>ul').each(function () {
      const $item = $(this);
      const $submenu = $item.children('ul');

      const open = () => {
        if (isMobile()) {
          if ($item.is('.active')) return;
          $submenu.slideDown(200);
          return;
        }
        $submenu.fadeIn(200);
      };

      const close = () => {
        if (isMobile()) {
          if ($item.is('.active')) return;
          $submenu.slideUp(200);
          return;
        }
        $submenu.fadeOut(200);
        $body.off('click.closesubmenu');
      };

      $item.hover(open, close);

      if (!$item.children('>a').lenght) {
        $item.on('click', () => {
          $body.off('click.closesubmenu');
          open();
          setTimeout(() => {
            $body.one('click.closesubmenu', close);
          }, 100);
        });
      }
    });
  });


  // Шапка / Сортировка
  $('#js-header-sorting').each(function () {
    const $sorting = $(this);
    const $current = $sorting.find('.js-current');
    const $menu = $sorting.find('.js-menu');

    window.toggleOnClick($current, $menu);
  });


  // Шапка / Поиск
  $('#js-header-search').each(function () {
    const $container = $(this);
    const $form = $container.find('form');
    const $button = $container.find('.js-open');
    const $close = $container.find('.js-close');
    const $input = $container.find('input');

    window.toggleOnClick($button, $form, {
      open: ($element) => {
        // $element.stop().animate({
        //   width: 516 + 'px',
        //   // opacity: 1,
        // }, 200, () => $input.focus())
        $body.addClass('header-search-opened');
      },
      close: ($element) => {
        // $element.stop().animate({
        //   width: 0,
        //   // opacity: 0,
        // }, 200);
        $body.removeClass('header-search-opened');
      },
    });

    $close.on('click', () => $form.trigger('toggleOnClickClose'));
  });


  // Мобильное меню
  {
    let slideMenu;
    const $hamburger = $('#js-hamburger');
    const $menu = $('#js-menu--mobile');
    const $main = $('#js-main');
    const $mobileHeader = $('#js-header--mobile');
    const $body = $('body');


    const init = () => {

      if (isMobile()) {
        
        if (!slideMenu) {

          $body.append($menu);
          $body.append($mobileHeader);

          slideMenu = new Slideout({
            'panel': $main.get(0),
            'menu': $menu.get(0),
            'padding': 256,
            //'tolerance': 70,
            'side': 'right'
          });

          slideMenu.enableTouch();

          slideMenu.on('beforeopen', () => {
            $hamburger.addClass('is-active');
          });
          slideMenu.on('beforeclose', () => {
            $hamburger.removeClass('is-active');
          });


          $hamburger.on('click.slidemenu', function () {
            slideMenu.toggle();
          });
        }
      }

      else {
        if (slideMenu) {

          $hamburger.removeClass('is-active');
          $main.append($menu);
          $main.prepend($mobileHeader);

          $menu.removeClass('slideout-menu').removeClass('slideout-menu-right');

          slideMenu.destroy();
          slideMenu = null;
          $hamburger.off('click.slidemenu');
        }
      }
    };

    init();
    window.onload = init;
    window.addEventListener('resize', init);
  }


  // Адаптивная структура
  // Положение блоков
  adaptiveSwapper({

    959: {

      '#js-header--mobile': [
        '#js-mobile-logo',
        '#js-header-sorting',
        '#js-feedback',
        '#js-hamburger',
      ],

      '#js-menu--mobile': [
        '#js-main-nav',
      ],
    },

    649: {
      '#js-header--mobile': [
        '#js-mobile-logo',
        '#js-header-sorting',
        '#js-hamburger',
      ],

      '#js-menu--mobile': [
        '#js-main-nav',
        '#js-feedback',
      ],
    },

  });

});


