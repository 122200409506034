import '../styles/standard.less';
import loadingTemplate from '../soy/standard.soy';


let loadingRectId = 1;

/**
   * Loading over block
   *
   * @param [params] настройки
   * @returns {fn}
   */
$.fn.loading = function (params) {
  params = $.extend({}, params);

  $(this).each(function () {
    var self = $(this);

    // Ставим класс на сам объект, чтобы к нему можно было применять стили
    self.addClass('loading-target');

    // Увеличиваем ID Loading
    loadingRectId++;

    // Метка о том, что поле в процессе Loading
    self.data('loading', true);

    var div = $(loadingTemplate.loading().content);
    div.attr('id', 'loading_rect_' + loadingRectId);
    self.attr('data-loading_id', loadingRectId);

    // Css Класс
    if (!params.css) {
      params.css = self.attr('data-loading');
    }
    if (params.css) {
      div.addClass(params.css);
    }

    if (params.onClick) {
      div.click(params.onClick).css('cursor', 'pointer');
    }

    // Размещение
    // Если это таблица
    if (self.is('td') || self.is('tr')) {
      self.closest('table').before(div);
    }
    // Обычный элемент
    else {
      self.before(div);
    }

    var bg = div.find('.js-loading-bg');

    var width = self.outerWidth();
    var height = self.outerHeight();

    self.css('opacity', 0);

    bg
      .outerWidth(width)
      .outerHeight(height)
      ;

    let el = self.get(0);
    let sourceStyles = getComputedStyle(el);
    let loadingBg = bg;

    if (sourceStyles.borderRadius) {
      loadingBg.css('border-radius', sourceStyles.borderRadius);
    }
    else {
      if (sourceStyles.borderBottomLeftRadius) {
        loadingBg.css('border-bottom-left-radius', sourceStyles.borderBottomLeftRadius);
      }
      if (sourceStyles.borderBottomRightRadius) {
        loadingBg.css('border-bottom-right-radius', sourceStyles.borderBottomRightRadius);
      }
      if (sourceStyles.borderTopLeftRadius) {
        loadingBg.css('border-top-left-radius', sourceStyles.borderTopLeftRadius);
      }
      if (sourceStyles.borderTopRightRadius) {
        loadingBg.css('border-top-right-radius', sourceStyles.borderTopRightRadius);
      }
    }




    // Отступ сверху
    var targetTop = self.offset().top;
    var loadingTop = div.offset().top;
    if (loadingTop !== targetTop) {
      var plusTop = targetTop - loadingTop;

      div.css('margin-top', plusTop + 'px');
    }


    // Смещение фона
    /*var x = 0;
     $(this).everyTime(40, function ()
     {
     x ++;
     $(bg).css('background-position', x+'px');
     });*/
  });

  return this;
};


/**
 * Remove loading
 */
$.fn.loadingStop = function () {
  $(this).each(function () {
    var self = $(this);

    self.addClass('loading-target');

    self.removeData('loading');
    self.css('opacity', 1);
    //self.stopTime().prev('.JS_loading_rect_over').remove();
    //self.stopTime();

    var id = self.attr('data-loading_id');
    $('#loading_rect_' + id).remove();
  });

  return this;
};


window.toggleOnClick = ($button, $target, { open, close } = {}) => {
  let canOpen = true;

  $button.on('click', () => {

    if (!canOpen) return;
    canOpen = false;

    if (open) open($target);
    else $target.fadeIn(150);

    let mouseOnTargetOvered = false;
    $target.on('mouseover', () => mouseOnTargetOvered = true);
    $target.on('mouseout', () => mouseOnTargetOvered = false);

    const _close = () => {
      if (close) close($target);
      else $target.fadeOut(150);

      document.removeEventListener('mousedown', closeByMouse);
      window.removeEventListener('keydown', closeByEscape);
      setTimeout(() => canOpen = true, 200);
    };

    $target.on('toggleOnClickClose', () => {
      _close();
    });

    const closeByMouse = e => {
      if (!mouseOnTargetOvered) {
        e.preventDefault();
        _close();
        return false;
      }
    };
    document.addEventListener('mousedown', closeByMouse);
    const closeByEscape = e => {
      if (e.keyCode === 27) _close();
    };
    window.addEventListener('keydown', closeByEscape);
  });
};


$.fn.placeholderBlock = function () {
  $(this).each(function () {
    const $input = $(this).find('input, textarea');
    const $placeholder = $(this).find('.js-placeholder');
    let focused = false;

    const update = () => {
      if ($input.val()) {
        $placeholder.hide();
      }
      else {
        $placeholder.show();
      }
    };

    $input.on('keydown keypress keyup', update)
      .on('focus', () => {
        $placeholder.addClass('placeholder-focused');
      })
      .on('blur', () => {
        $placeholder.removeClass('placeholder-focused');
      });

    update();
  });

  return this;
};


$.fn.serializeObject = function () {
  var o = {};
  var a = this.serializeArray();

  $.each(a, function () {
    if (o[this.name] !== undefined) {
      if (!o[this.name].push) {
        o[this.name] = [o[this.name]];
      }
      o[this.name].push(this.value || '');
    }
    else {
      o[this.name] = this.value || '';
    }
  });

  return o;
};


const updateScrollBarsWidth = () => {  
  document.documentElement.style.setProperty('--scrollbar-width', (
    window.innerWidth
    - document.documentElement.clientWidth) + "px"
  );
};

updateScrollBarsWidth();
window.addEventListener('resize', updateScrollBarsWidth);
window.onload = updateScrollBarsWidth;


